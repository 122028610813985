import React from "react"

import { Card } from "../Card/Card"
import { CardsSectionProps } from "./CardsSection.types"

import "./CardsSection.css"

export default function CardsSection(props: CardsSectionProps) {
  const { cards, title, background_color } = props
  return (
    <div
      className="landing-contentful-cards-section"
      style={{ background: background_color }}
    >
      <h3 className="landing-contenful-cards-section__title">{title}</h3>
      <div className="landing-contenful-cards-section__cards">
        {cards.map((card, index) => (
          <Card key={`card-${title}-${index}`} {...card} />
        ))}
      </div>
    </div>
  )
}
