import React, { useMemo } from "react"

import {
  Options,
  documentToReactComponents,
} from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES, Text } from "@contentful/rich-text-types"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"

import {
  isWebpSupported,
  optimize,
  optimizeVideo,
  useImageOptimization,
} from "../../../hooks/contentful"
import Video from "../../Video/Video"
import Image from "../Image"
import { ContenfulUIComponentType } from "../types"

import "./RichText.css"

function getRichTextOptions(referencesMap: Record<string, any>): Options {
  return {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        const content = node.content as Text[]
        return (
          <a href={node.data.uri} target="_blank" className="create-tab__link">
            {content[0].value}
          </a>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const reference = referencesMap[node.data.target.sys.id]
        if (!reference) {
          return null
        }

        if (reference.mimeType === "video/mp4") {
          const optimizedVideo = optimizeVideo(reference.url)
          return (
            <Video
              className="landscape"
              loop
              muted
              autoPlay
              playsInline={true}
              width={reference.width}
              height={reference.height}
              source={optimizedVideo || ""}
            />
          )
        }

        const optimizedImage = optimize(reference.url)
        return (
          <Image
            format={isWebpSupported() ? `webp` : `jpg`}
            src={optimizedImage.optimized}
            width={reference.width}
            height={reference.height}
          />
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const reference = referencesMap[node.data.target.sys.id]
        if (
          reference?.sys?.contentType?.sys?.id ===
          ContenfulUIComponentType.BUTTON
        ) {
          const additionalProps = { [reference.type]: true }
          return (
            <Button href={reference.href} {...additionalProps}>
              {reference.label}
            </Button>
          )
        } else if (
          reference?.content_type === ContenfulUIComponentType.BUTTONS_CONTAINER
        ) {
          return (
            <div className="landing-rich-text__buttons-container">
              {reference.buttons.map((buttonRef: any) => (
                <Button href={buttonRef.href} {...{ [buttonRef.type]: true }}>
                  {buttonRef.label}
                </Button>
              ))}
            </div>
          )
        }
        return null
      },
    },
    renderText: (text) => unescape(text),
  }
}

export default function RichText({
  raw,
  references,
  className,
}: {
  raw: string
  references?: any[]
  className?: string
}) {
  const referencesMap = useMemo(
    () =>
      references?.reduce((referencesObj, reference) => {
        referencesObj[reference.contentful_id] = reference
        return referencesObj
      }, {}) || {},
    [references]
  )

  return (
    <div className={TokenList.join([className, "landing-rich-text"])}>
      {documentToReactComponents(
        JSON.parse(raw),
        getRichTextOptions(referencesMap)
      )}
    </div>
  )
}
