import React from "react"

import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import { useImageOptimization } from "../../../hooks/contentful"
import RichText from "../RichText/RichText"
import { CardProps } from "./Card.types"

import "./Card.css"

export function Card(props: CardProps) {
  const { title, image, subtitle, background, card_style, border } = props

  const optimizedImage = useImageOptimization(image.url)
  return (
    <div
      className={TokenList.join([
        "landing-contenful-card",
        `landing-contenful-card--${card_style || "primary"}`,
      ])}
      style={{ background, border }}
    >
      {image && (
        <img
          src={optimizedImage.optimized}
          className="landing-contenful-card__image"
          alt={title}
        />
      )}
      <h3 className="landing-contenful-card__title">{title}</h3>
      <RichText className="landing-contenful-card__subtitle" {...subtitle} />
    </div>
  )
}
