import React from "react"

import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import { SkillTagProps } from "./SkillTag.types"

import "./SkillTag.css"

export default function SkillTag(props: SkillTagProps) {
  const { title, icon, level } = props
  return (
    <div
      className={TokenList.join([
        "landing-contenful-skill-tag",
        level === null && "landing-contenful-skill-tag--center",
      ])}
    >
      <div className="landing-contenful-skill-tag__info">
        <img
          className="landing-contenful-skill-tag__icon"
          src={icon.url}
          alt={title}
        />
        <span className="landing-contenful-skill-tag__title">{title}</span>
      </div>
      {level !== null && (
        <div className="landing-contenfull-skill-tag__levels">
          {Array(5)
            .fill("")
            .map((_value, index) => (
              <span
                key={`${title}-level-${index}`}
                className={TokenList.join([
                  "landing-contenfull-skill-tag__level",
                  index < level
                    ? "landing-contenfull-skill-tag__level--active"
                    : "",
                ])}
              />
            ))}
        </div>
      )}
    </div>
  )
}
