import React from "react"

import { ImageOptimized, useImageOptimization } from "../../hooks/contentful"

export type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  format?: keyof ImageOptimized
}

export default function Image({ ...props }: ImageProps) {
  const preferred = props.format || "optimized"
  const image = useImageOptimization(props.src)
  const selected = image.originalFormat === "gif" ? image.gif : image[preferred]
  return <img {...props} src={selected} />
}
