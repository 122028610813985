import React from "react"

import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"

import { EventNavbarProps } from "./EventNavbar.types"

import "./EventNavbar.css"

export default function EventNavbar({ logo, title, id }: EventNavbarProps) {
  const l = useFormatMessage()
  return (
    <div className="event-navbar">
      <img className="event-navbar__logo" src={logo} alt={title} />
      <div className="event-navbar__links">
        <a className="event-navbar__link" href="/">
          {l("component.event_navbar.decentraland")}
        </a>
        <a
          className="event-navbar__link event-navbar__link--active"
          href={`/event/${id}`}
        >
          {title}
        </a>
        <a className="event-navbar__link" href="/create">
          {l("component.event_navbar.creators")}
        </a>
        <a className="event-navbar__link" href="https://docs.decentraland.org">
          {l("component.event_navbar.docs")}
        </a>
      </div>
    </div>
  )
}
