import React from "react"

import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import { Button } from "decentraland-ui/dist/components/Button/Button"

import notFoundImg from "../../images/not_found.png"
import Layout from "../Layout/Layout"

import "./NotFound.css"

export default function NotFound() {
  const l = useFormatMessage()
  return (
    <Layout className="landing-not-found">
      <div className="landing-not-found__container">
        <img src={notFoundImg} alt="not found" />
        <div className="landing-not-found__info">
          <h1 className="landing-not-found__title">
            {l("page.not_found.title")}
          </h1>
          <span>{l("page.not_found.message")}</span>
        </div>
        <Button as="a" primary href="/">
          {l("page.not_found.action")}
        </Button>
      </div>
    </Layout>
  )
}
