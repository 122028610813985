import React from "react"

import RichText from "../RichText/RichText"
import { BannerProps } from "./Banner.types"

import "./Banner.css"

export default function Banner(props: BannerProps) {
  const { background_color, info } = props
  return (
    <div className="contentful-banner" style={{ background: background_color }}>
      <RichText {...info} />
    </div>
  )
}
