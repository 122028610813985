import React from "react"

import SkillTag from "../SkillTag/SkillTag"
import { SkillTagProps } from "../SkillTag/SkillTag.types"
import { ListSectionProps } from "./ListSection.types"

import "./ListSection.css"

export default function ListSection(props: ListSectionProps) {
  const { title, items, background_color } = props
  return (
    <div
      className="landing-contenful-list-section"
      style={{ background: background_color }}
    >
      <h3 className="landing-contentful-list-section__title">{title}</h3>
      <div className="landing-contenful-list-section__list">
        {items.map((item: SkillTagProps) => (
          <SkillTag key={item.title} {...item} />
        ))}
      </div>
    </div>
  )
}
