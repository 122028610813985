import { graphql, useStaticQuery } from "gatsby"

import { BannerProps } from "../components/Contentful/Banner/Banner.types"
import { CardsSectionProps } from "../components/Contentful/CardsSection/CardsSection.types"
import { CarouselSectionProps } from "../components/Contentful/CarouselSection/CarouselSection.types"
import { ListSectionProps } from "../components/Contentful/ListSection/ListSection.types"
import { MainBannerProps } from "../components/Contentful/MainBanner/MainBanner.types"

export type Event = {
  contentful_id: string
  title: string
  logo: { url: string }
  event_id: string
  typeform_quiz_id: string
  main_banner: MainBannerProps
  background_color: string
  dispensers_keys: string[]
  claim_success_background: { url: string }
  claim_success_background_mobile: { url: string }
  claim_banner: { url: string }
  sections: (
    | CardsSectionProps
    | CarouselSectionProps
    | ListSectionProps
    | BannerProps
  )[]
}

export default function useEventQuery(eventId: string): Event | undefined {
  const contentfulQueryResult = useStaticQuery(graphql`
    query EventQuery {
      page: allContentfulEventPage {
        nodes {
          logo {
            url
          }
          title
          event_id
          typeform_quiz_id
          contentful_id
          background_color
          dispensers_keys
          claim_success_background {
            url
          }
          claim_success_background_mobile {
            url
          }
          claim_banner {
            url
          }
          main_banner {
            background {
              title
              size
              url
              mimeType
              width
              height
            }
            background_mobile {
              title
              size
              url
              mimeType
              width
              height
            }
            top_info {
              raw
              references {
                ... on ContentfulUiButton {
                  id
                  href
                  label
                  type
                  contentful_id
                  sys {
                    type
                    contentType {
                      sys {
                        id
                        type
                        linkType
                      }
                    }
                  }
                }
                ... on ContentfulAsset {
                  contentful_id
                  mimeType
                  url
                  height
                  width
                }
                ... on ContentfulUiButtonsContainer {
                  contentful_id
                  content_type
                  buttons {
                    id
                    href
                    label
                    type
                  }
                }
              }
            }
            middle_info {
              raw
              references {
                ... on ContentfulUiButton {
                  id
                  href
                  label
                  type
                  contentful_id
                  sys {
                    type
                    contentType {
                      sys {
                        id
                        type
                        linkType
                      }
                    }
                  }
                }
                ... on ContentfulAsset {
                  contentful_id
                  mimeType
                  url
                  height
                  width
                }
                ... on ContentfulUiButtonsContainer {
                  contentful_id
                  content_type
                  buttons {
                    id
                    href
                    label
                    type
                  }
                }
              }
            }
            bottom_info {
              raw
              references {
                ... on ContentfulUiButton {
                  id
                  href
                  label
                  type
                  contentful_id
                  sys {
                    type
                    contentType {
                      sys {
                        id
                        type
                        linkType
                      }
                    }
                  }
                }
                ... on ContentfulAsset {
                  contentful_id
                  mimeType
                  url
                  height
                  width
                }
                ... on ContentfulUiButtonsContainer {
                  contentful_id
                  content_type
                  buttons {
                    id
                    href
                    label
                    type
                  }
                }
              }
            }
          }
          sections {
            ... on ContentfulUiCardsSection {
              id
              title
              min_card_size
              background_color
              cards {
                background
                card_style
                border
                subtitle {
                  raw
                }
                title
                image {
                  url
                }
              }
              content_type
            }
            ... on ContentfulUiBanner {
              id
              background_color
              content_type
              info {
                raw
                references {
                  ... on ContentfulUiButton {
                    id
                    href
                    label
                    type
                    contentful_id
                    sys {
                      type
                      contentType {
                        sys {
                          id
                          type
                          linkType
                        }
                      }
                    }
                  }
                  ... on ContentfulAsset {
                    contentful_id
                    url
                    height
                    width
                  }
                }
              }
            }
            ... on ContentfulUiCarousel {
              id
              title
              min_card_size
              background_color
              items {
                background
                card_style
                image {
                  url
                }
                subtitle {
                  raw
                }
                title
              }
              content_type
            }
            ... on ContentfulUiListSection {
              id
              title
              items {
                title
                icon {
                  url
                }
                level
              }
              background_color
              content_type
            }
          }
        }
      }
    }
  `)

  return (contentfulQueryResult.page.nodes as Event[]).find(
    ({ event_id }: Event) => eventId === event_id
  )
}
