import React from "react"

import { useMobileMediaQuery } from "decentraland-ui/dist/components/Media/Media"
import { Swiper, SwiperSlide } from "swiper/react"

import { Card } from "../Card/Card"
import { CarouselSectionProps } from "./CarouselSection.types"

import "./CarouselSection.css"

const MIN_SIZE = 250
const PADDING = 32

function getSlidesPerView(
  breakpoint: number,
  minSize: number = MIN_SIZE,
  itemsLength: number
) {
  return Math.min((breakpoint - PADDING) / minSize, itemsLength)
}

export default function CarouselSection(props: CarouselSectionProps) {
  const { title, items, min_card_size, background_color } = props
  return (
    <div
      className="landing-contenful-carousel"
      style={{ background: background_color }}
    >
      <h3 className="landing-contentful-carousel__title">{title}</h3>
      <Swiper
        slidesPerView={1}
        spaceBetween={16}
        breakpoints={{
          320: {
            slidesPerView: getSlidesPerView(320, min_card_size, items.length),
          },
          480: {
            slidesPerView: getSlidesPerView(480, min_card_size, items.length),
          },
          800: {
            slidesPerView: getSlidesPerView(800, min_card_size, items.length),
          },
          1200: {
            slidesPerView: getSlidesPerView(1200, min_card_size, items.length),
          },
          1800: {
            slidesPerView: getSlidesPerView(1800, min_card_size, items.length),
          },
        }}
        className="landing-contenful-carousel__swiper"
      >
        {items.map((item) => (
          <SwiperSlide key={item.title}>
            <Card {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
